import { Grid, Link } from '@mui/material';
import { FunctionComponent } from 'react';

// eslint-disable-next-line require-jsdoc
const ModalPrivacidade: FunctionComponent<{ software?: string }> = ({
  software,
}) => {
  return (
    <Grid
      item
      className={`barlow--size16 white`}
      style={{ textDecoration: 'underline' }}
      component={Link}
      target="_blank"
      href={
        software
          ? `https://fd476e3224c3440308ea0a1.blob.core.windows.net/devyx/${software}_politica_de_privacidade.pdf`
          : 'https://fd476e3224c3440308ea0a1.blob.core.windows.net/devyx/politicas_de_privacidade.pdf'
      }
    >
      Política de Privacidade
    </Grid>
  );
};

export default ModalPrivacidade;
